@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 255, 255, 255;
  --background-end-rgb: 255, 255, 255;
}

/* Write your own custom base styles here */
@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }

  html,
  body {
    color: #000000;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: #000;
  }

  p {
    color: #495057;
    @apply mb-5;
  }

  p:last-of-type {
    @apply mb-0;
  }

  p>a {
    @apply transition;
  }

}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

body {
  color: rgb(var(--foreground-rgb));
  /* background: linear-gradient(to bottom, transparent,rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb)); */
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

/* .top-line-bg {
  background-image: url("/images/banner-bg.webp");
  background-size: cover;
} */


.hover-box:hover .e8824a path {
  fill: #e8824a
}

.hover-box:hover .ee4E96DA path {
  fill: #4E96DA
}

.hover-box:hover .e34A68F path {
  fill: #34A68F
}

.hover-box:hover .eEC407A path {
  fill: #EC407A
}

.hover-box:hover .e985E41 path {
  fill: #985E41
}

.hover-box:hover .e78909C path {
  fill: #78909C
}

.hover-box:hover .e4E96DA path {
  fill: #4E96DA
}

.hover-box:hover .eEC5B48 path {
  fill: #EC5B48
}

.hover-box:hover .eA3755E path {
  fill: #A3755E
}


@media only screen and (min-width: 901px) and (max-width: 1119px) {
  .benefits-box .box-1,
  .benefits-box .box-3 {
    width: 35%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .video-slider .react-player {
    height: 440px !important;
  }
}

.react-tel-input .flag-dropdown {
  border-right: 0 !important;
}

.react-tel-input .selected-flag {
  padding: 0 0 0 20px !important;
}

.react-tel-input .form-control {
  padding-left: 60px !important;
}

a {
  color: #611F69;
}

@supports (-ms-ime-align:auto) {
  .swiper.swiper-initialized.swiper-horizontal .play-icon {
    left: 5rem;
  }
}


_::-webkit-full-page-media,
_:future,
:root .safari_only {
  left: 9.8 rem;
}

.from-purple-300 {
  background: rgb(196,181,253);
  background: linear-gradient(90deg, rgba(196,181,253,1) 0%, rgba(191,219,254,1) 100%);
}
